import React,{useEffect, useState} from 'react'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  } from 'chart.js';

import { Line } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import reportServices from '../../Report/reportServices';
import LastReading from '../../Report/components.js/LastReading';
import MinMaxReading from '../../Report/components.js/MinMaxReading';
import { Link } from 'react-router-dom';
import { BarChart, Memory, Settings } from '@mui/icons-material';
import { borderColor } from '@mui/system';
import { useSelector } from 'react-redux';
import NetworkDetailsComponent from './NetworkDetailsComponent';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

function DeviceDashboardReport({device, deviceCount }) {


    const authUser = useSelector((state) => state.auth)
    const userId=  authUser.id;


    const [chartLabels,setChartLabels] = useState([]);
    
    const [chartLabel1,setChartLabel1] = useState("");
    const [chartLabel2,setChartLabel2] = useState("");

    const [chartUnit1,setChartUnit1] = useState(null);
    const [chartUnit2,setChartUnit2] = useState(null);

    const [chart1Min,setChart1Min] = useState(null);
    const [chart2Min,setChart2Min] = useState(null);
    const [chart1Max,setChart1Max] = useState(null);
    const [chart2Max,setChart2Max] = useState(null);

    

    const [chartData1,setChartData1] = useState([]);
    const [chartData2,setChartData2] = useState([]);

    const setupChartData = (data,sensorList) =>{

        let labels = [];
        labels = data.map(d=>d.time)
        setChartLabels(labels);

        let label1 = sensorList[0].sensorType;
        setChartLabel1(label1);

        setChartUnit1(sensorList[0].sensorUnit);

        setChart1Min(sensorList[0].lowAlarmValue);
        setChart1Max(sensorList[0].highAlarmValue);


        let s1Data = [];
        s1Data = data.map(d=>d[sensorList[0].channel]);
        setChartData1(s1Data);

        
        let label2 = "";
        let unit2 = "";
        //console.log("device",device);
        //console.log("userid",userId);

        if(device.Device_ID == "STWFMB0122009" || userId != 12){
            label2 = sensorList[1].sensorType;
            unit2 = sensorList[1].sensorUnit;

            setChart2Min(sensorList[1].lowAlarmValue);
            setChart2Max(sensorList[1].highAlarmValue);
        } else {
            label2 = sensorList[2].sensorType;
            unit2 = sensorList[2].sensorUnit;

            setChart2Min(sensorList[2].lowAlarmValue);
            setChart2Max(sensorList[2].highAlarmValue);
        }
        setChartLabel2(label2);
        setChartUnit2(unit2);
        let s2Data = [];
        if(device.Device_ID == "STWFMB0122009" || userId != 12 ){
            s2Data = data.map(d=>d[sensorList[1].channel]);
        } else {
            s2Data = data.map(d=>d[sensorList[2].channel]);
        }
        
        setChartData2(s2Data);



    }


    const getDeviceDashboardData = ()=>{
        reportServices.getDahboardReport(device.Device_ID).then(res=>{
            //console.log(res.senssorList);

            setupChartData(res.data,res.senssorList)



        })

        /*
        let data={
            labels: ["12:01","12:02","12:03","12:04","12:05"],
            datasets: [
                {
                    label: "Dataset 1",
                    data: [5,6,7,8,Math.floor(Math.random() * 10) + 1]
                }
            ]
        }
        */
        //setChartData(data);
    }


    useEffect(() => {
        
        getDeviceDashboardData()
        
        const interval = setInterval(() => {
          getDeviceDashboardData()
        }, 60000);
        return () => clearInterval(interval);

    }, []);



    const prepairDataSet = (chartLabel,chartData,chartMin,chartMax ) =>{
        let datasets = [];
        datasets.push({
                label: chartLabel,
                data: chartData,
                backgroundColor :"rgba(54, 162, 235, 0.8)",
                borderColor:"rgba(54, 162, 235, 1)",
            }
        )


        if(chartMin){
            datasets.push({
                label: "Min",
                data: chartData.map(c=>chartMin),
                borderColor:"#F00",
                backgroundColor :"rgba(54, 162, 235, 0)",
                borderDash:[1,1]
            })
        }

        if(chartMax){
            datasets.push({
                label: "Max",
                data: chartData.map(c=>chartMax),
                borderColor:"#0F0",
                backgroundColor :"rgba(54, 162, 235, 0)",
                borderDash:[1,1]
            })
        }

        


        return datasets;

    }
    

    return (
        <div className="card">
            <div className="row">
                <div className="col-md-6 pt-2 ">
                    <strong className="px-2">{device.displayTitle} | {device.location} </strong>
                </div>
                <div className="col-md-6">
                    <div className="px-2">
                        <div class="d-flex justify-content-end">
                            <NetworkDetailsComponent device={device} />
                            {device.fwv !== null &&
                                <div className="bg-dark text-white mt-2 mx-2 p-1 rounded"><Memory /> <span className="fw-bold">{device.fwv}</span></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="row">
                <div className="col-md-12">
                                <div className="row">
                                    <div className={deviceCount===1?"col-md-6":"col-md-12"}>

                                        <div className="d-flex d-flex justify-content-center" >
                                            <div className="d-flex align-items-center">
                                                <span style={{textOrientation:"mixed", writingMode:"vertical-lr", fontSize:10,}}>{chartUnit1}</span>
                                            </div>
                                            <div style={deviceCount===1?{width:"100%"}:{}}>
                                                {chartData1 && 
                                                    <Line data={{
                                                        
                                                        labels: chartLabels,
                                                        datasets: prepairDataSet(chartLabel1,chartData1, chart1Min,chart1Max)



                                                        }} 
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={deviceCount===1?"col-md-6":"col-md-12"}>
                                        <div className="d-flex d-flex justify-content-center" >
                                            <div className="d-flex align-items-center">
                                                <span style={{textOrientation:"mixed", writingMode:"vertical-lr", fontSize:10,}}>{chartUnit2}</span>
                                            </div>
                                            <div style={deviceCount===1?{width:"100%"}:{}}>
                                                {chartData2 && 
                                                    <Line data={{
                                                        labels: chartLabels,
                                                        datasets: prepairDataSet(chartLabel2,chartData2, chart2Min,chart2Max)
                                                        }} 
                                                    />
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                

                    
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="float-end">
                        <Link className="btn btn-light me-3" to={`/device/config/${device.id}`}> Settings <Settings/> </Link>
                        <Link className="btn btn-light" to={`/report/device/${device.Device_ID}`}> View Report <BarChart/> </Link>
                    </div>
                </div>
            </div>
            
            
                
            

            <div className="row">
                <div className="col-md-12">
                    <LastReading deviceId={device.Device_ID} />
                </div>
                <div className="col-md-12">
                    <MinMaxReading deviceId={device.Device_ID} />
                </div>
            </div>


            {device.deviceImage &&
            <div className="row">
                <div className="col-md-12 text-center">
                    <img style={{height:200,width:200}} src={process.env.REACT_APP_BASE_URL+"uploads/images/"+device.deviceImage} />
                </div>
            </div>
            }
            
        </div>
        
    )
}

export default DeviceDashboardReport



///borderDash: [15, 5],